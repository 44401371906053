import { useCallback } from "react";
import './App.css';
import Particles from "react-particles";

import { Engine } from "tsparticles-engine";
import { loadStarsPreset } from "tsparticles-preset-stars";

function App() {
    const particlesInit = useCallback(async (engine: Engine) => {
        loadStarsPreset(engine);
    }, []);
    const options = {
        preset: "stars",
    };

  return (
      <div className="App">
      <Particles options={options} init={particlesInit} className={`space-background`} />
      <header className="App-header">
        <h1>Fox Media Encoder</h1>
        <p>
          An easy to use GUI interface for multimedia file transcoding.
        </p>
        <p>Needs <a href="https://ffmpeg.org/" target="_blank" style={{ color: "white" }}>FFmpeg</a> to transcode video and audio.</p>
          
          <div className='parent-buttons'>
              <a href="https://apps.microsoft.com/store/detail/9P1W070L1HWH?launch=true&mode=mini">
                  <img src="https://get.microsoft.com/images/en-US%20light.svg" alt="Microsoft Store" height={50} />
              </a>
              <form action="./download">
                  <button type="submit" className="download-btn">
                      Download
                  </button>
              </form>
          </div>

          <form action="https://roseusfox.com/contacts">
            <button type="submit" className="contacts-btn">
                Contacts
            </button>
        </form>
      </header>

      <footer>
        <a href="/privacy.html">Privacy Policy</a>
      </footer>
    </div>
  );
}

export default App;
